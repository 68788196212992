import React from 'react';
import { graphql } from 'gatsby';
import Layout from '~components/layouts/Default';
import Container from '~components/common/Container';
import PodcastHeader from '~components/podcast/PodcastHeader';
import PodcastSectionTitle from '~components/podcast/PodcastSectionTitle';
import PodcastCardEpisode from '~components/podcast/PodcastCardEpisode';
import { convertDate } from '../../functions/formating/convertDate';
import GridThree from '~components/common/GridThree';
import GridTwo from '~components/common/GridTwo';
import PodcastListEpisodeIndex from '~components/podcast/PodcastListEpisodeIndex';
import P from '~components/typography/P';
import H2 from '~components/typography/H2';
import H3 from '~components/typography/H3';
import SvgSpotify from '~components/common/svgs/SvgSpotify';
import SvgItunes from '~components//common/svgs/SvgItunes';
import SvgGooglePodcast from '~components/common/svgs/SvgGooglePodcast';
import SvgAnchorFm from '~components/common/svgs/SvgAnchorFm';

export default function Page({ data, location }) {
  return (
    <Layout location={location} title="Entspannungshelden Podcast" desc="Entspannungshelden ist dein wöchentlicher Arschtritt für mehr Lebensqualität." active="Podcast" green>
      <PodcastHeader
        image={data.header.nodes[0].remoteTitelbild}
        subtitle="Neuste Folge"
        title={data.header.nodes[0].titel}
        to={`/podcast/folgen/${data.header.nodes[0].folgennummer}/${data.header.nodes[0].slug_slug}/`}
        meta={`Folge ${data.header.nodes[0].folgennummer} - ${convertDate(data.header.nodes[0].datum)} - Thema: ${data.header.nodes[0].thema.display}`}
        text={data.header.nodes[0].einleitung}
        buttonText="Folge abspielen"
      />

      <Container>
        <H2 className="mb-5 font-display">Der Entspannungshelden Podcast</H2>
        <GridTwo>
          <div>
            <P className="mb-3">
              „Müde, erschöpft und lustlos fühlte ich mich noch vor einigen Jahren in meiner Arbeit als „Supernanny“. Mein Alltag als Jugendhelfer war geprägt von Überforderung, Ahnungslosigkeit und Ressourcenmangel. Nicht nur bei meinen
              Klienten, sondern auch bei mir. Als Pädagoge habe ich so ziemlich alle Fehler gemacht, die ich machen konnte. Bin meine Grenzen übergangen, habe Schutzfaktoren aufgegeben und mich völlig verausgabt, bis es zum Zusammenbruch
              kam. Ich wünschte wirklich, ich hätte das Wissen von heute schon damals im Studium erworben. So hätte ich mich selber schützen und Abgrenzen können, hätte meine Stressreaktionen erkannt und Mittel und Wege gehabt, um mich
              wieder zu erden und zu entspannen.{' '}
            </P>
            <P className="mb-3">
              „Müde, erschöpft und lustlos fühlte ich mich noch vor einigen Jahren in meiner Arbeit als „Supernanny“. Mein Alltag als Jugendhelfer war geprägt von Überforderung, Ahnungslosigkeit und Ressourcenmangel. Nicht nur bei meinen
              Klienten, sondern auch bei mir. Als Pädagoge habe ich so ziemlich alle Fehler gemacht, die ich machen konnte. Bin meine Grenzen übergangen, habe Schutzfaktoren aufgegeben und mich völlig verausgabt, bis es zum Zusammenbruch
              kam. Ich wünschte wirklich, ich hätte das Wissen von heute schon damals im Studium erworben. So hätte ich mich selber schützen und Abgrenzen können, hätte meine Stressreaktionen erkannt und Mittel und Wege gehabt, um mich
              wieder zu erden und zu entspannen.{' '}
            </P>
          </div>
          <div>
            <P className="mb-3">
              Der Entspannungshelden Podcast ist wie für dich gemacht, wenn du keine Lust auf obiges Lebensgefühl hast. Und wenn du dich für Entspannung und Stressmanagement interessierst. Grundlegende Konzepte werden von Johannes einfach
              und mit lebhaften Beispielen erklärt, so dass du sofort verstehst, wie Stress wirkt und was du dagegen tun kannst.
            </P>
            <P className="mb-3">
              Dich erwarten hierbei eine Mischung aus Wissensaufbau und Informationsvermittlung, anwendbaren Strategien und Werkzeugen, sowie aus Entspannungsverfahren, die du für dich nutzen kannst, wann immer es dir passt.{' '}
            </P>
            <P className="mb-3">
              Damit du dich besser orientieren kannst, hast du die Möglichkeit über die Kategorien selbst zu wählen ob du dich weiterbilden und Stressgeschehen besser verstehen möchtest, oder ob du einfach nur Entspannung in Forme einer
              Traumreise oder ähnlichem brauchst.
            </P>
          </div>
        </GridTwo>
        <GridTwo>
          <div>
            <H3 className="mb-5 font-display">Wissenswertes</H3>
            <P className="mb-3">
              In diesem Bereich vermitteln wir dir Informationen, tauschen uns mit Experten der Branche aus und möchten dich in erster Linie dabei unterstützen, Stress und Entspannung zu verstehen und zu reflektieren. Von A wie Ausgebrannt
              bis Z wie Zähneknirschen behandeln wir alle Felder querbeet und gleichzeitig vernetzt, so dass du einen großen Wissensschatz gewinnst, mit dem du deinen Alltag besser reflektieren kannst.{' '}
            </P>
          </div>
          <div>
            <H3 className="mb-5 font-display">Werkzeuge</H3>
            <P className="mb-3">
              Gerade im zwischenmenschlichen Umgang gibt es viele tolle Methoden und Werkzeuge die teilweise sehr einfach, aber manchmal auch hochkomplex sind. Im Podcast stellen wir dir die unterschiedlichste Methoden vor und laden dich
              ein, diese zu üben und zu trainieren. Grundlegend haben wir die meisten der Methoden selbst ausprobiert und können unsere Erfahrungen mit dir Teilen.
            </P>
          </div>
          <div>
            <H3 className="mb-5 font-display">Entspannung</H3>
            <P className="mb-3">
              Manchmal braucht es weder Wissen noch Methoden, sondern einfach nur eine Pause. Damit du Orts- und Zeitunabhängig entspannen kannst, bieten wir dir in regelmäßigen Abständen Traumreisen und Meditationen an, die von Johannes,
              Salva oder Sybille gesprochen werden. Die Länge variiert dabei zwischen 3 - 25 Minuten, so dass du je nach Lebenslage genau auswählen kannst, was du gerade brauchst.{' '}
            </P>
          </div>
          <div>
            <H3 className="mb-5 font-display">Hörerlebnisse wann und wo du willst</H3>
            <P className="mb-3">
              Natürlich findest du unseren Podcast auch bei allen bekannten Podcastportalen wie Apple Podcast, Spotify, Anchor etc. Lass uns gerne ein Abo da, damit du immer informiert bist, wenn eine neue Folge kommt. Auf den Podcastseiten
              hast du Möglichkeit eine Bewertung zu hinterlassen und kannst uns schreiben, welche Themen dich interessieren. Gibst du uns Feedback, können wir gezielt auf die Dinge eingehen und dich so unterstützen.
            </P>
            <div className="grid grid-cols-1 gap-2 lg:grid-cols-2">
              <a
                href="https://podcasts.apple.com/de/podcast/entspannungshelden/id1550796827"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center rounded-xl border border-gray-300 p-3 text-white shadow transition duration-300 hover:shadow-none"
                style={{ backgroundColor: '#000000' }}
              >
                <SvgItunes className="mr-3 inline h-6 w-6" /> Apple Podcast
              </a>
              <a
                href="https://podcasts.apple.com/de/podcast/entspannungshelden/id1550796827"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center rounded-xl border border-gray-300 p-3 text-white shadow transition duration-300 hover:shadow-none"
                style={{ backgroundColor: '#7b4cdb' }}
              >
                <SvgAnchorFm className="mr-3 inline h-6 w-6" /> AnchorFM
              </a>
              <a
                href="https://open.spotify.com/show/0YLyHoKVt8R7UlR6qNM9Kv?si=fa64ee2cf44f4775"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center rounded-xl border border-gray-300 p-3 text-white shadow transition duration-300 hover:shadow-none"
                style={{ backgroundColor: '#1DB954' }}
              >
                <SvgSpotify className="mr-3 inline h-6 w-6" /> Spotify
              </a>
              <a
                href="https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy80OTk4YjA2OC9wb2RjYXN0L3Jzcw=="
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center rounded-xl border border-gray-300 p-3 text-white shadow transition duration-300 hover:shadow-none"
                style={{ backgroundColor: '#4285F4' }}
              >
                <SvgGooglePodcast className="mr-3 inline h-6 w-6" /> Google Podcast
              </a>
            </div>
          </div>
        </GridTwo>
        <GridThree className="my-16">
          {data.allPodcastThemen.nodes.map((topic, i) => {
            let matchinEpisodes = [];
            data.listAll.nodes.forEach((episode) => {
              if (topic.thema === episode.thema.display) {
                matchinEpisodes.push(episode);
              }
            });
            if (matchinEpisodes[0] !== undefined) {
              return (
                <section key={`topic-${i}`}>
                  <PodcastSectionTitle title={`Folgen zu ${topic.thema}`} linktext="Alle Folgen" to={`/podcast/folgen/${topic.slug_slug}`} color={topic.farbe} className="mb-6" />
                  <PodcastCardEpisode
                    title={matchinEpisodes[0].titel}
                    image={matchinEpisodes[0].remoteTitelbild}
                    meta={`Folge ${matchinEpisodes[0].folgennummer} - ${convertDate(matchinEpisodes[0].datum)}`}
                    text={matchinEpisodes[0].einleitung}
                    to={`/podcast/folgen/${matchinEpisodes[0].folgennummer}/${matchinEpisodes[0].slug_slug}/`}
                    color={topic.farbe}
                    className="mb-6"
                  />
                  <PodcastListEpisodeIndex listArray={matchinEpisodes.slice(1, 4)} />
                </section>
              );
            }

            return <></>;
          })}
        </GridThree>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query {
    header: allPodcastFolgen(sort: { fields: folgennummer, order: DESC }, limit: 1, filter: { public: { eq: true } }) {
      nodes {
        titel
        slug_slug
        thema {
          display
        }
        remoteTitelbild {
          childImageSharp {
            gatsbyImageData
          }
        }
        einleitung
        folgennummer
        datum
      }
    }

    allPodcastThemen {
      nodes {
        farbe
        thema
        slug_slug
      }
    }

    listAll: allPodcastFolgen(sort: { fields: folgennummer, order: DESC }, skip: 1, filter: { public: { eq: true } }) {
      nodes {
        titel
        slug_slug
        thema {
          display
        }
        remoteTitelbild {
          childImageSharp {
            gatsbyImageData
          }
        }
        einleitung
        folgennummer
        datum
      }
    }
  }
`;
