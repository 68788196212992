import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import Button from '../common/Button';
import Container from '../common/Container';
import GridTwo from '../common/GridTwo';
import PageHeader from '../common/PageHeader';
import SvgPlay from '../common/svgs/SvgPlay';
import H2 from '../typography/H2';
import P from '../typography/P';

const Header = ({ image, subtitle, title, to, text, buttonText, meta, color }) => {
  return (
    <div className="bg-brand-green/50 pb-8">
      <PageHeader title="Podcast" breadcrumb={[{ title: 'Wissen', link: '/wissen/' }, { title: 'Podcast' }]} />
      <Container noMargin>
        <GridTwo>
          <div className="mb-6 overflow-hidden rounded-xl md:mb-0">
            <Link to={to}>
              <GatsbyImage image={getImage(image)} className="rounded-xl align-top duration-300 hover:scale-105" alt={`${title}`} title={`${title}`} />
            </Link>
          </div>
          <div className="flex items-center text-brand-gray">
            <div>
              <span>{subtitle}</span>
              <H2 className="mb-5 font-bold">
                <Link to={to} className={`hover:underline`}>
                  {title}
                </Link>
              </H2>
              <div className="mb-3 text-xs">{meta}</div>

              <P textColor="text-brand-gray" className="mb-3">
                {text}
              </P>
              <Button to={to}>
                <SvgPlay className="mr-3 h-8 w-8" /> {buttonText}
              </Button>
            </div>
          </div>
        </GridTwo>
      </Container>
    </div>
  );
};

export default Header;
