import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

const ListEpisodeIndex = ({ listArray, className }) => {
  const data = useStaticQuery(graphql`
    query {
      allPodcastThemen {
        nodes {
          thema
          farbe
        }
      }
    }
  `);

  return (
    <div className={className ? className : ''}>
      {listArray.map((item, i) => {
        let color;
        data.allPodcastThemen.nodes.forEach((topic) => {
          if (topic.thema === item.thema.display) {
            color = topic.farbe;
          }
        });

        return (
          <div className={`grid grid-cols-8 ${listArray.length === i + 1 ? '' : 'mb-8'}`} key={i}>
            <div className={`col-span-1`}>
              <Link to={`/podcast/folgen/${item.folgennummer}/${item.slug_slug}/`}>
                <GatsbyImage image={getImage(item.remoteTitelbild)} className="w-full rounded align-top duration-300 hover:scale-105" alt={item.titel} />
              </Link>
            </div>
            <div className={`col-span-7 flex flex-col justify-center pl-3`}>
              <h3 className={`font-display text-lg`}>
                <Link to={`/podcast/folgen/${item.folgennummer}/${item.slug_slug}/`} className={`${color !== undefined ? `transition-shadow duration-300 hover:underline` : ''}`}>
                  {item.titel}
                </Link>
              </h3>
              <span className="text-xs text-gray-500">Folge: {item.folgennummer}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ListEpisodeIndex;
