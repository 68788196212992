import { ChevronRightIcon } from '@heroicons/react/outline';
import { Link } from 'gatsby';
import React from 'react';
import H2 from '../typography/H2';

const SectionTitle = ({ title, linktext, to, className, color }) => {
  return (
    <div className={className ? className : ''}>
      <H2 className={`inline font-display font-bold`}>{title}</H2>
      <br />
      {linktext && to ? (
        <Link to={to} className="mt-1 inline-block uppercase text-gray-400 hover:text-gray-900">
          {linktext} <ChevronRightIcon className="-mt-1 inline h-4 w-4" />
        </Link>
      ) : (
        ''
      )}
    </div>
  );
};

export default SectionTitle;
