import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import SvgPlay from '../common/svgs/SvgPlay';
import H3 from '../typography/H3';
import P from '../typography/P';

const CardEpisode = ({ image, to, title, meta, text, className, color }) => {
  return (
    <div className={className ? className : ''}>
      <div className="mb-6 overflow-hidden rounded-xl">
        <Link to={to}>
          <div className="relative">
            <GatsbyImage image={getImage(image)} className="align-top duration-300 hover:scale-105" alt={title} />
            <SvgPlay className="absolute bottom-4 left-4 h-16 w-16 duration-300 hover:scale-105" />
          </div>
        </Link>
      </div>
      <div className="flex items-center">
        <div>
          <H3 className="mb-4">
            <Link to={to} className={`${color !== undefined ? `font-display duration-300 hover:underline` : ''}`}>
              {title}
            </Link>
          </H3>
          <div className="mb-3 text-xs">{meta}</div>
          <P className="mb-3">{text}</P>
        </div>
      </div>
    </div>
  );
};

export default CardEpisode;
